import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import siteData from '../../content/siteData.yaml'

import './styles.sass'

const PageFooter = ({ slim = false }) => {
  return (
    <footer className={`instar-page-footer${slim === true ? ` v--slim` : ''}`}>
      <div className="container">
        <div className="row">
          {slim !== true && (
            <React.Fragment>
              <div className="col-12 col-md-1 brandmark">
                <img src="/assets/brandmark.svg" alt="Ironstar" />
              </div>
              <div className="col-12 col-md-5 col-lg-7 resources">
                <Link className="secondary h6 footer__hdr" to="/">
                  Enterprise Hosting
                </Link>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Link className="secondary" to={siteData.links.form}>
                      {siteData.links.formLinkText}
                    </Link>
                    {/* <Link className="secondary" to="/features-services">
            Features &amp; Services
          </Link> */}
                    {/* <Link className="secondary" to="/blog">
            Blog
          </Link> */}
                    <OutboundLink
                      href="https://tokaido.io"
                      className="secondary"
                      target="_blank"
                    >
                      Tokaido
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="ml-2 icon icon--small"
                      />
                    </OutboundLink>
                    {/* <Link className="secondary" to="/faqs">
            FAQs
          </Link> */}
                    {/* <Link className="secondary" to="/docs">
            Documentation
          </Link> */}
                  </div>

                  <div className="col-12 col-sm-6">
                    {/* <div className="secondary h6 footer__hdr">For Customers</div> */}
                    <Link className="secondary" to={siteData.links.console}>
                      Login to Console
                    </Link>
                    <OutboundLink
                      className="secondary"
                      href={siteData.links.support}
                    >
                      {/* <FontAwesomeIcon icon={faHeadset} className="icon" /> &nbsp;*/}
                      {siteData.links.supportLinkText}
                    </OutboundLink>
                    <OutboundLink
                      className="secondary"
                      href="https://support.ironstar.io/hc/en-au/categories/900001438803-How-To"
                    >
                      How-To Articles
                    </OutboundLink>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 contact">
                <Link
                  className="secondary h6 footer__hdr"
                  to={siteData.links.form}
                >
                  Contact Ironstar
                </Link>
                <div className="row">
                  <div className="col-6">
                    <a
                      className="secondary"
                      href={`tel:${siteData.contact.tel}`}
                    >
                      {siteData.contact.telLinkText}
                    </a>
                    <a href={`mailto:${siteData.contact.email}`}>
                      {siteData.contact.email}
                    </a>
                    <br />
                  </div>
                  <div className="col-6 address">
                    Collins Street Tower
                    <br />
                    Level 3<br />
                    480 Collins Street
                    <br />
                    Melbourne VIC 3000
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          <div
            className={`col-12 col-md-8 ${
              !slim ? `offset-md-1` : ''
            } col-lg-9 legal`}
          >
            <div className='acknowledgement'>
              Ironstar acknowledges Traditional Owners of Country throughout Australia and recognises the continuing  connection to lands, waters and communities. We pay our respect to Aboriginal and Torres Strait Islander cultures; and to Elders past and present.
            </div>

            &copy; Ironstar Hosting Services. &nbsp;
            <span>
              <Link className="secondary" to="/privacy/">
                Privacy
              </Link>
            </span>
            {/* <span>
            <Link className="secondary" to="/terms-conditions/">
              Terms &amp; Conditions
            </Link>
          </span> */}
            <span>
              <a
                className="secondary"
                href="/assets/documents/ironstar-security.pdf"
              >
                Security
              </a>
            </span>
            <span>
              <Link className="secondary" to="/vdp/">
                Vulnerability Disclosure Policy
              </Link>
            </span>
          </div>
          <div className="col-12 col-md-3 col-lg-2 social">
            <OutboundLink
              target="_blank"
              rel="noreferrer"
              href="http://github.com/ironstar-io"
            >
              <span className="icon icon--rounded">
                <img
                  className=""
                  src="/assets/icons/social/github.svg"
                  alt="GitHub"
                />
              </span>
            </OutboundLink>
            <OutboundLink
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/ironstar"
            >
              <span className="icon icon--rounded">
                <img
                  className=""
                  src="/assets/icons/social/linkedin.svg"
                  alt="LinkedIn"
                />
              </span>
            </OutboundLink>
            {/* <OutboundLink href="https://www.youtube.com/channel/UCZSfGmHSFLRqBRVIaxH9QXw">
            <span className="icon icon--rounded"><img className="" src="/assets/icons/social/youtube.svg" alt="Youtube" /></span>
          </OutboundLink> */}
            <OutboundLink
              target="_blank"
              rel="noreferrer"
              href="http://twitter.com/ironstar_io"
            >
              <span className="icon icon--rounded">
                <img
                  className=""
                  src="/assets/icons/social/twitter.svg"
                  alt="Twitter"
                />
              </span>
            </OutboundLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default PageFooter
