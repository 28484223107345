import React from 'react'
import { Link } from 'gatsby'

import './styles.sass'

const Logo = () => (
  <Link to="/" className="logo order-1">
    <img className="img-logo" src="/assets/logo.png" alt="" />
    <img className="txt-logo" src="/assets/logo-txt.svg" alt="Ironstar" />
  </Link>
)

export default Logo
